import { StoreButton } from '@components/atoms/Button/component'
import { Introduction } from '@components/molecules/Introduction/component'
import style from './style.module.css'

export const Main = (): JSX.Element => {
  return (
    <div className={style.main}>
      <span>
        <Introduction />
        <div className={style['store-btn-wrapper']}>
          <StoreButton url='https://www.happyfriends.app/' storeType='ios'>App Store</StoreButton>
          <StoreButton url='https://www.happyfriends.app/' storeType='android'>Google Play</StoreButton>
        </div>
      </span>
    </div>
  )
}
