import { Main } from '@components/organisms/Main/component'
import { Mockup } from '@components/organisms/Mockup/component'
import React from 'react'
import style from './style.module.css'

export const HomePage = (): JSX.Element => {
  React.useEffect(() => {
    document.body.classList.remove('privacy-policy')
  }, [])

  return (
    <div className={style.content}>
      {/* left side */}
      <Main />
      {/* right side  */}
      <Mockup />
    </div>
  )
}
