import tiktok from '@resources/tiktok_logo.svg'
import insta from '@resources/insta_logo.svg'
import web from '@resources/web_logo.svg'
import style from './style.module.css'
import { motion } from 'framer-motion'

interface Props {
  url: string
  mediaType: 'tiktok' | 'instagram' | 'web'
}

export const SocialsButton = (props: Props): JSX.Element => {
  const mediaLogo = (): string => {
    switch (props.mediaType) {
      case 'tiktok':
        return tiktok
      case 'instagram':
        return insta
      case 'web':
      default:
        return web
    }
  }

  return (
    <motion.span
      whileHover={{ scale: 1.2 }}
      transition={{
        ease: 'easeInOut',
        duration: 0.5
      }}
    >
      <motion.a
        href={props.url}
        target='_blank'
        className={style['social-btn']}
      >
        <img src={mediaLogo()} alt={`logo van ${props.mediaType}`} />
      </motion.a>
    </motion.span>
  )
}
