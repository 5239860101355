import { Boxes } from '@components/molecules/Boxes/component'
import style from './style.module.css'

export const Mockup = (): JSX.Element => {
  return (
    <div className={style.mockup}>
      <Boxes />
      <div className={style['blue-wave']} />
      <picture>
        <source media='(max-width: 600px)' srcSet='mockups@0_5.webp' type='image/webp' />
        <source media='(max-width: 1440px)' srcSet='mockups.webp' type='image/webp' />
        <source media='(min-width: 1441px)' srcSet='mockups@2x.webp' type='image/webp' />

        <source media='(max-width: 600px)' srcSet='mockups@0_5.png' />
        <source media='(max-width: 1440px)' srcSet='mockups.png' />
        <source media='(min-width: 1441px)' srcSet='mockups@2x.png' />

        <img srcSet='mockups.webp' alt='mockup van App je Happy' />
      </picture>
    </div>
  )
}
