import React from 'react'
import { motion } from 'framer-motion'
import style from './style.module.css'
import clsx from 'clsx'

export const Boxes = (): JSX.Element => {
  const infinite = {
    duration: 2,
    ease: 'easeInOut',
    repeat: Infinity
  }

  const scaleUp = { scale: [1, 1.2, 1] }
  const scaleDown = { scale: [1, 0.8, 1] }

  return (
    <>
      {/* yellow boxes top */}
      {/* nth:children 1 to 5 */}
      <motion.div
        className={clsx(style.box, style['yellow-box'], style['box-74'])}
        animate={scaleUp}
        transition={infinite}
      />
      <motion.div
        className={clsx(style.box, style['yellow-box'], style['box-74'])}
        animate={scaleDown}
        transition={infinite}
      />
      <motion.div
        className={clsx(style.box, style['yellow-box'], style['box-74'])}
        animate={scaleDown}
        transition={infinite}
      />
      <motion.div
        className={clsx(style.box, style['yellow-box'], style['box-54'])}
        animate={scaleDown}
        transition={infinite}
      />
      <motion.div
        className={clsx(style.box, style['yellow-box'], style['box-54'])}
        animate={scaleUp}
        transition={infinite}
      />

      {/* blue boxes */}
      <motion.div
        className={clsx(style.box, style['blue-box'], style['box-56'])}
        initial={{ x: '-50%', y: '-50%' }}
        animate={{ ...scaleUp, x: '-50%', y: '-50%' }}
        transition={infinite}
      />
      {/* nth-children 1 nd 2 */}
      <div className={style['blue-r']}>
        <div>
          <motion.div
            className={clsx(style.box, style['blue-box'], style['box-56'])}
            animate={scaleDown}
            transition={infinite}
          />
          <motion.div
            className={clsx(style.box, style['blue-box'], style['box-41'])}
            animate={scaleUp}
            transition={infinite}
          />
        </div>
      </div>
    </>
  )
}
