import React from 'react'
import style from './App.module.css'
import { Header } from '@components/organisms/Header/component'
import { Footer } from '@components/organisms/Footer/component'
import { HomePage } from '@components/templates/HomePage/component'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { PrivacyPage } from '@components/templates/PrivacyPage/component'
import { PageNotFound } from '@components/templates/NotFound/component'
import useWindowSize from 'utils/use-window-size'

// eslint-disable-next-line
function App(): JSX.Element {
  useWindowSize()
  return (
    <Router>
      <div className={style.app}>
        <Header />
        {/* main content of page */}
        {/* <section className={style.content}> */}
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/privacy_policy' element={<PrivacyPage />} />

          {/* catches all other paths */}
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        {/* </section> */}
        <Footer />
      </div>
    </Router>
  )
}

export default App
