import { SocialsButton } from '@components/atoms/SocialsButton/component'
import './style.css'

export const SocialMedia = (): JSX.Element => {
  return (
    <div className='socials'>
      <SocialsButton mediaType='tiktok' url='https://www.tiktok.com/@happyfriendsproject' />
      <SocialsButton mediaType='instagram' url='https://www.instagram.com/happyfriendspositiveminds/' />
      <SocialsButton mediaType='web' url='https://www.happyfriends.app' />
    </div>
  )
}
