import React from 'react'
import style from './style.module.css'

export const FooterLinks = (): JSX.Element => {
  const currYear = new Date().getFullYear()

  return (
    <ul className={style.list}>
      <li>
        © App je Happy, {currYear}
      </li>
      <li>
        <a href='mailto:happyfriends@hr.nl' target='_blank' rel='noreferrer'>
          Contact
        </a>
      </li>
      <li>
        <a href='/privacy_policy' target='_blank' rel='noreferrer'>
          Privacy
        </a>
      </li>
    </ul>
  )
}
