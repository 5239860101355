import style from './style.module.css'
import { FooterLinks } from '@components/molecules/FooterLinks/component'
import bottomWave from '@resources/bottom_wave.svg'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'

export const Footer = (): JSX.Element => {
  const location = useLocation()

  return (
    <footer
      className={clsx(
        style.footer,
        location.pathname === '/privacy_policy' && style['footer-pp']
      )}
    >
      <div className={style['bottom-wave']}>
        <img src={bottomWave} alt='afbeelding van een grijze golf als schijdingslijn' />
      </div>
      <FooterLinks />
    </footer>
  )
}
