import React from 'react'

// eslint-disable-next-line
export function isClient(): boolean {
  return typeof window !== 'undefined'
}
interface Size {
  width: number | undefined
  height: number | undefined
  isTablet: boolean
}

// eslint-disable-next-line
export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: undefined,
    height: undefined,
    isTablet: false
  })

  React.useEffect(() => {
    // eslint-disable-next-line
    function handleResize(): void {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isTablet: window.innerWidth >= 668
      })

      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
