import style from './style.module.css'
import React from 'react'
import { BoxSmall } from '@components/atoms/BoxSmall/component'
import { BoxLarge } from '@components/atoms/BoxLarge/component'
import useWindowSize from 'utils/use-window-size'

export const PrivacyPage = (): JSX.Element => {
  React.useEffect(() => {
    document.body.classList.add('privacy-policy')
  }, [])

  const scaleUp = { scale: [1, 1.2, 1] }
  const scaleDown = { scale: [1, 0.8, 1] }

  const windowSize = useWindowSize()

  return (
    <div className={style.container}>

      <div className={style['box-container-start']}>
        {windowSize.isTablet && (
          <>
            <BoxSmall top='755px' left='-9px' animate={scaleDown} />
            <BoxSmall top='1092px' left='76px' animate={scaleUp} />
            <BoxSmall top='1449px' left='39px' animate={scaleUp} />
            <BoxLarge top='205px' left='21px' animate={scaleUp} />
            <BoxLarge top='711px' left='17px' animate={scaleUp} />
            <BoxLarge top='1467px' left='0' animate={scaleDown} />
          </>
        )}
      </div>

      <div className={style['box-container-end']}>
        {windowSize.isTablet && (
          <>
            <BoxSmall top='264px' right='21px' animate={scaleUp} />
            <BoxSmall top='795px' right='58px' animate={scaleDown} />
            <BoxSmall top='1474px' right='93px' animate={scaleDown} />
            <BoxLarge top='226px' right='46px' animate={scaleDown} />
            <BoxLarge top='552px' right='-23px' animate={scaleUp} />
            <BoxLarge top='1146px' right='-5px' animate={scaleUp} />
          </>
        )}
      </div>

      <div className={style.privacy}>
        <div>
          <h1>Privacyverklaring</h1>
          <p>
            Jij doet samen met een vriendin mee aan het Happy Friends, Positive Minds onderzoek. Jullie hebben van de
            onderzoekers van Hogeschool Rotterdam uitleg gekregen over wat het betekent om mee te doen aan het
            onderzoek. Jij en je ouders/verzorgers hebben de onderzoekers toestemming gegeven om de informatie die jij
            geeft over jezelf, over hoe je je voelt en over je gezondheid voor het onderzoek te mogen gebruiken.
          </p>
          <p>
            Deze App je Happy app is ook onderdeel van het onderzoek. Wanneer jij het Dagboek invult en aangeeft welke
            emoties je voelt, verzamelt de app gegevens over jou, over hoe je je voelt en over je gezondheid. Deze
            informatie wordt in de app bewaard en wordt ook bewaard in beveiligde bestanden op beveiligde computers
            van de onderzoekers. Jij bent natuurlijk benieuwd hoe we dat precies doen. Hieronder lees je daar meer over.
            Lees deze informatie goed door.
          </p>

          {/* mb-0 meaning margin-bottom 0 */}
          <p className={style['mb-0']}>Deze dingen zijn belangrijk om te weten:</p>
          <ol>
            <li>
              <span>
                Je mag de hoofdonderzoeker van Hogeschool Rotterdam altijd bellen, e-mailen of een Whatsapp-
                berichtje sturen als je vragen of zorgen hebt over de app. Zij heet Patricia Vuijk, haar telefoonnummer
                is <a href='tel:06-15959704'>06-15959704</a> en haar email-adres is <a href='mailto:p.vuijk@hr.nl'>p.vuijk@hr.nl</a>.
                Alles wat je met haar bespreekt is vertrouwelijk. Niemand anders krijgt de informatie die je met haar deelt te lezen of te horen.
              </span>
            </li>
            <li>Hogeschool Rotterdam is verantwoordelijk voor het veilig bewaren van jouw gegevens.</li>
            <li>Jij mag de gegevens die de app over jou bewaart altijd inzien. Als je dit wilt, kun je contact opnemen met Patricia Vuijk.</li>
            <li>
              Je kunt de gegevens die je in de app hebt ingevuld niet meer aanpassen als er een dag voorbij is.
              Mocht je toch dringend iets willen veranderen, kun je contact opnemen met Patricia Vuijk.
            </li>
            <li>
              <span>
                De Functionaris Gegevensbescherming van Hogeschool Rotterdam controleert of de
                hoofdonderzoeker op een veilige manier met jouw gegevens omgaat en zich goed aan alle afspraken
                houdt. Heb je daar een vraag of een klacht over, kun je een e-mail bericht sturen
                aan <a href='mailto:functionarisgegevensbescherming@hr.nl'>functionarisgegevensbescherming@hr.nl</a>.
              </span>
            </li>
            <li>
              Je zult je app drie maanden gaan gebruiken. Om goed met de app te leren werken, krijg je van de
              onderzoekers tijdens deze drie maanden iedere week een (online) training. Tijdens de eerste
              bijeenkomst ga je de app voor het eerst gebruiken.
            </li>
            <li>
              <span>
                Om de app te kunnen gebruiken heb je een gebruikersnaam en een wachtwoord nodig, die je tijdens
                de eerste trainingsbijeenkomst aanmaakt. Bewaar die gegevens goed. Ben je je gebruikersnaam en/of
                je wachtwoord kwijt, neem dan binnen 24 uur contact op met Patricia Vuijk door te bellen
                naar <a href='tel:06-15959704'>06-15959704</a>, of door een e-mail te sturen aan <a href='mailto:p.vuijk@hr.nl'>p.vuijk@hr.nl</a>.
              </span>
            </li>
            <li>
              Wanneer je de app tijdens de training voor het eerst opent, vraag je jouw unieke code op in het menu
              (daar helpen we je bij). Deze code geef je door aan de onderzoeker die jou de training geeft. De
              onderzoeker geeft jouw naam en jouw unieke code telefonisch door aan de hoofdonderzoeker.
            </li>
            <li>
              De koppeling tussen jouw naam en jouw unieke code wordt in een beveiligd bestand op de beveiligde
              computer van de hoofdonderzoeker opgeslagen. Niemand anders dan de hoofdonderzoeker heeft
              toegang tot dit bestand.
            </li>
            <li>
              De app verzamelt de gegevens die jij over jezelf, over hoe je je voelt en over je gezondheid invult. Deze
              gegevens worden tijdens het gebruiken van de app aan de hand van jouw unieke code anoniem en
              automatisch opgeslagen in een beveiligd bestand op de beveiligde computer van YipYip, het bedrijf
              dat de app gemaakt heeft.
            </li>
            <li>
              Alleen de hoofdonderzoeker kan in dit bestand dat jouw antwoorden uit de app opslaat met jouw
              unieke code de koppeling maken tussen jouw naam en jouw gegevens. Niemand anders weet dus wat
              jij in de app hebt ingevuld.
            </li>
            <li>
              <span>
                Wanneer je je telefoon met deze app kwijtraakt of wanneer de app tijdelijk niet werkt, neem dan
                direct telefonisch contact op met Patricia Vuijk via <a href='tel:06-15959704'>06-15959704</a>.
              </span>
            </li>
            <li>
              Alles wat je in de app over jezelf schrijft, is vertrouwelijk. De onderzoekers van YipYip en Hogeschool
              Rotterdam die jouw gegevens kunnen inzien, tekenen een verklaring dat zij jouw informatie niet met
              anderen delen.
            </li>
            <li>
              Je mag altijd en zonder opgave van reden stoppen met het onderzoek en dus ook met het werken met
              de app. Wil je stoppen, neem dan contact op met de hoofdonderzoeker. De gegevens die je tot dat
              moment via de app hebt gedeeld, mogen wij nog wel voor het onderzoek gebruiken.
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
