import { SocialMedia } from '@components/molecules/SocialMedia/component'
import logo from '@resources/logo.svg'
import style from './style.module.css'

// eslint-disable-next-line
export const Header = (): JSX.Element => {
  return (
    <header className={style.header}>
      <a href='/'>
        <img id='ajhLogo' src={logo} alt='logo van App je Happy' />
      </a>
      <SocialMedia />
    </header>
  )
}
