import style from './style.module.css'
import React from 'react'

// eslint-disable-next-line
export const PageNotFound = (): JSX.Element => {
  return (
    <div className={style['not-found']}>
      <h1>Oeps! Die pagina bestaat niet!</h1>
    </div>
  )
}
