import { motion } from 'framer-motion'
import style from './style.module.css'

interface Props {
  top?: string
  left?: string
  right?: string
  animate: any
}

export const BoxSmall = (props: Props): JSX.Element => {
  const infinite = {
    duration: 2,
    ease: 'easeInOut',
    repeat: Infinity
  }

  return (
    <motion.div
      className={style.test}
      initial={{
        top: props.top,
        left: props.left,
        right: props.right
      }}
      animate={props.animate}
      transition={infinite}
    />
  )
}
