import style from './style.module.css'
import { motion } from 'framer-motion'
import React from 'react'

export const Introduction = (): JSX.Element => {
  const title = 'Daar word je happy van!'
  const titleArr = title.split(' ')
  const paragraph = 'App je Happy helpt jou en je vriendinnen meer positiviteit in je dag te zien. Track je mood, leer positief terugkijken op gebeurtenissen en vind leuke dingen om samen met je vriendinnen te doen.'

  const initial = { opacity: 0, y: 15, rotate: '3deg' }
  const animate = { opacity: 1, y: 0, rotate: '0deg' }

  const delayDur = 0.2
  const duration = 1

  return (
    <>
      <h1 className={style['intro-header']}>
        {titleArr.map((text, index) => {
          if (index === 2) {
            return (
              <React.Fragment key={`title_${text}`}>
                <motion.span
                  className={style['text-part']}
                  initial={initial}
                  animate={animate}
                  transition={{
                    duration: duration,
                    ease: 'easeInOut',
                    delay: (index * delayDur)
                  }}
                >
                  {text}
                </motion.span>
                <br />
              </React.Fragment>
            )
          }
          return (
            <motion.span
              key={`title_${text}`}
              className={style['text-part']}
              initial={initial}
              animate={animate}
              transition={{
                duration: duration,
                ease: 'easeInOut',
                delay: (index * delayDur)
              }}
            >
              {text}
            </motion.span>
          )
        })}
      </h1>

      <motion.p
        className={style['intro-p']}
        initial={{ opacity: 0, x: -20, y: 20, rotate: '2deg', scale: 0.8 }}
        animate={{ opacity: 1, x: 0, y: 0, rotate: '0deg', scale: 1 }}
        transition={{
          duration: duration,
          ease: 'easeInOut',
          delay: (titleArr.length + 1.5) * delayDur
        }}
      >
        {paragraph}
      </motion.p>
    </>
  )
}
