import React from 'react'
import apple from '@resources/apple_logo.svg'
import playstore from '@resources/playstore_logo.svg'
import style from './style.module.css'
import { motion } from 'framer-motion'
import clsx from 'clsx'

interface Props {
  children: React.ReactNode
  url: string
  storeType: 'ios' | 'android'
}

export const StoreButton = (props: Props): JSX.Element => {
  const title = 'Daar word je happy van!'
  const titleArr = title.split(' ')
  const delayDur = 0.2
  const duration = 1

  const appleLogo = style['apple-logo']
  const playLogo = style['play-logo']

  const storeBtn = props.storeType === 'ios' ? style['apple-btn'] : style['playstore-btn']

  const returnLogo = (): string => {
    return props.storeType === 'ios' ? apple : playstore
  }

  return (
    <motion.span
      initial={{ opacity: 0, x: -50, y: 10, rotate: '3deg', scale: 0.8 }}
      animate={{ opacity: 1, x: 0, y: 0, rotate: '0deg', scale: 1 }}
      transition={{
        duration: duration,
        ease: 'easeInOut',
        delay: (titleArr.length + 4) * delayDur
      }}
    >

      <motion.a
        href={props.url}
        className={clsx(storeBtn, style['store-btn'])}
        target='_blank'
        whileHover={{ scale: 1.1 }}
        transition={{
          ease: 'easeInOut',
          duration: 0.5
        }}
      >
        <div>
          <img
            src={returnLogo()}
            className={props.storeType === 'ios' ? appleLogo : playLogo}
            alt={props.storeType === 'ios' ? 'logo van App Store' : 'logo van Playstore'}
          />
        </div>
        {props.children}
      </motion.a>
    </motion.span>

  )
}
